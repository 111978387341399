import React from "react"
import {navigate} from "gatsby"

import Layout from "../components/layout"
import PageWidth from "../components/page-width"
import SearchBar from "../components/search-bar"

const NotFound = (): JSX.Element => {
  const handleSearch = (query: string) => {
    navigate(`/search?q=${query}`)
  }

  return (
    <Layout title={"404 - Pagina niet gevonden"}>
      <PageWidth text>
        <h3>Pagina niet gevonden</h3>

        <p>
          De pagina die u probeerde te openen kon niet worden gevonden. Dit kan
          de volgende oorzaak hebben:
        </p>
        <ul>
          <li>De pagina bestaat niet.</li>
          <li>
            De pagina bestaat, maar u heeft geen rechten om de pagina te
            bekijken.
          </li>
        </ul>

        <br />
        <h4>Zoeken op de website</h4>
        <SearchBar handleSearch={handleSearch} />
      </PageWidth>
    </Layout>
  )
}

export default NotFound
